<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1000px"
  >
    <v-card>
      <v-card-title>
        <span
          class="text-h5"
        >予約判定</span>
      </v-card-title>
      <v-card-text>
        <v-container class="ma-0 pa-0" style="max-width:100%">
          <v-row align="center">
            <v-col
              cols="12"
            >
              <v-data-table
                ref="selReservedTable"
                v-model="selectedCustomers"
                :headers="headers"
                :items="selectedReserveds"
                item-key="resv_id"
                hide-default-footer
                sort-by
                show-select
                class="elevation-1"
              >
              </v-data-table>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <span class="pl-2" style="font-size:16px;">判定結果</span>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-select
                v-model="operation"
                :items="operationList"
                hide-details
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions align="center" class="d-flex justify-center">
        <v-btn
          class="mx-2"
          color="primary"
          @click="submit()"
        >
          OK
        </v-btn>
        <v-btn
          class="mx-2"
          outlined
          @click="closeForm()"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mapActions,
} from 'vuex'

export default {
  props: ['dialog', 'selectedReserveds'],
  data: () => ({
    operation: 0,
    selectedCustomers: [],
    operationList: [
      {
        text: '承認', value: 9,
      },
      {
        text: '却下', value: 2,
      },
    ],
    apierror: [],
    headers: [
      {
        text: 'ID.',
        align: 'left',
        value: 'resv_id',
        width: '10%',
      },
      {
        text: 'Pellet',
        align: 'left',
        value: 'pellet_id',
        width: '15%',
      },
      {
        text: '予約商品ID',
        align: 'left',
        value: 'po_code',
        width: '15%',
      },
      {
        text: '取引先',
        value: 'cust_abbr',
        align: 'left',
        width: '15%',
      },
      {
        text: '予約日時',
        value: 'resv_dt',
        align: 'left',
        width: '25%',
      },
      {
        text: '数量',
        value: 'resv_cnt',
        align: 'right',
        width: '10%',
      },
      {
        text: '状態',
        value: 'resv_stat_desc',
        sortable: false,
        align: 'center',
        width: '10%',
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '5%',
      },
    ],
  }),
  computed: {
  },
  watch: {
    dialog(val) {
      if (val) {
        console.log('dialog', val, this.$refs.selReservedTable)
        this.$nextTick(() => {
          this.$refs.selReservedTable.toggleSelectAll(true)
        })
      }
    },
  },
  methods: {
    ...mapActions('reservedStore', ['decideReserved']),

    closeForm() {
      this.selectedCustomers = []
      this.$emit('closeForm')
    },

    submit() {
      console.log('customers:', this.selectedCustomers)

      if (this.selectedCustomers.length === 0) {
        // eslint-disable-next-line no-alert
        window.alert('取引先を選択してください。')

        return
      }

      console.log('selectedCustomers:', this.selectedCustomers)

      const submitForm = {
        result: this.operation,
        details: this.selectedCustomers,
      }

      console.log('submitForm:', submitForm)

      this.decideReserved(submitForm)
        .then(() => {
          this.closeForm()
        })
        .catch(error => {
          this.apierror.status = error.response.data.status
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        })
    },
  },
}
</script>
