<template>
  <div id="reserved-list">
    <v-card>
      <v-card-title>予約実績(一覧)</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col cols="12" md="2">
          <v-autocomplete
            v-model="pellet_id"
            :items="pelletIds"
            placeholder="Pellet"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2">
          <v-autocomplete
            v-model="po_code"
            :items="preorderCodes"
            placeholder="予約商品"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2">
          <v-autocomplete
            v-model="cust_abbr"
            :items="customerMaster"
            placeholder="取引先"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2">
          <v-autocomplete
            v-model="resv_stat"
            :items="resvStatusMaster"
            placeholder="予約状態"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="2">
          <v-menu
            v-model="dateStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                placeholder="予約日From"
                dense
                hide-details
                outlined
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="startDate" @input="dateStartMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="2">
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                placeholder="予約日To"
                dense
                outlined
                hide-details
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="endDate" @input="dateEndMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <!-- <v-btn
            color="primary"
            class="mb-4 me-3"
            :disabled="OperationDisabled"
            @click="openOperationDialog"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiFileCheckOutline }}
            </v-icon>
            <span>操作</span>
          </v-btn> -->
          <v-btn color="secondary" outlined class="mb-4">
            <v-icon size="17" class="me-1">
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        v-model="selectedReserveds"
        :headers="headers"
        :items="reservedList"
        :search="searchQuery"
        item-key="resv_id"
        show-select
        class="elevation-1"
      >
        <template v-slot:[`item.resv_id`]="{ item }">
          <a
            href="javascript:void(0);"
            @click="pushProgress(item)"
          >{{ item.resv_id }}</a>
        </template>
        <template v-slot:[`item.po_code`]="{ item }">
          <a
            href="javascript:void(0);"
            :style="'text-decoration: underline;' "
            @click="openReservedPreivew(item)"
          >{{ item.po_code }}</a>
        </template>
        <template v-slot:[`item.cust_abbr`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a
                v-bind="attrs"
                :style="'text-decoration: underline; color:#666666' "
                v-on="on"
              >{{ item.cust_abbr }}</a>
            </template>
            <span>{{ item.cust_id }}</span><br>
            <span>{{ item.cust_name }}</span><br>
            <span>{{ item.cust_name_eng }}</span><br>
          </v-tooltip>
        </template>
        <template v-slot:[`item.resv_count`]="{ item }">
          {{ formatData(item.resv_count) }}
        </template>
        <template v-slot:[`item.resv_weight`]="{ item }">
          {{ formatData(item.resv_weight) }}
        </template>
        <template v-slot:[`item.resv_amount`]="{ item }">
          {{ item.ccy_mark }}{{ formatPrice(item.resv_amount) }}
        </template>
        <template v-slot:[`item.resv_stat_desc`]="{ item }">
          <div class="d-flex justify-end">
            <v-chip
              style="width:100px"
              :color="getColor(item.resv_stat)"
              class="d-flex justify-center"
            >
              {{ item.resv_stat_desc }}
            </v-chip>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <!-- <v-icon small :disabled="isDisabledEdit(item)" @click="editItem(item)">
            {{ icons.mdiPencil }}
          </v-icon> -->
          <v-icon small :disabled="item.resv_stat !== 1" @click="editItem(item)">
            {{ item.resv_stat !== 1 ? icons.mdiPencilOff : icons.mdiPencil }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <reserved-operation
      :dialog="dialog"
      :selected-reserveds="selectedReserveds"
      @closeForm="closeOperationDialog"
    ></reserved-operation>
    <ReservedPreview
      :id="selectedResvId"
      :resv-dialog="resvDialog"
      @closeForm="closeReservedPreivew"
    ></ReservedPreview>
  </div>
</template>

<script>
import {
  mdiExportVariant, mdiPencil, mdiDelete, mdiFileCheckOutline, mdiPencilOff,
} from '@mdi/js'
import {
  mapActions, mapMutations, mapState,
} from 'vuex'
import ReservedOperation from './ReservedOperation.vue'
import ReservedPreview from './ReservedPreview.vue'

export default {
  components: {
    ReservedOperation,
    ReservedPreview,
  },
  data: () => ({
    icons: {
      mdiPencil,
      mdiDelete,
      mdiExportVariant,
      mdiFileCheckOutline,
      mdiPencilOff,
    },
    dialog: false,
    resvDialog: false,
    selectedResvId: '',
    selectedReserveds: [],
    OperationDisabled: true,
    startDate: '',
    endDate: '',
    dateEndMenu: false,
    dateStartMenu: false,
    preorderIds: [],
    preorderCodes: [],
    reserve_id: '',
    cust_abbr: '',
    searchQuery: '',
    pellet_id: '',
    po_id: '',
    po_code: '',
    resv_stat: '',
    headers: [
      {
        text: 'ID.',
        align: 'left',
        value: 'resv_id',
        width: '8%',
      },
      {
        text: 'Pellet',
        align: 'left',
        value: 'pellet_id',
        width: '10%',
      },
      {
        text: '予約商品',
        align: 'left',
        value: 'po_code',
        width: '12%',
      },
      {
        text: '取引先',
        value: 'cust_abbr',
        align: 'left',
        width: '12%',
      },
      {
        text: '予約日時',
        value: 'resv_dt',
        align: 'left',
        width: '12%',
      },
      {
        text: '数量',
        value: 'resv_cnt',
        align: 'right',
        width: '10%',
      },
      {
        text: '総重量',
        value: 'resv_weight',
        align: 'right',
        width: '10%',
      },
      {
        text: '総金額',
        value: 'resv_amount',
        align: 'right',
        width: '10%',
      },
      {
        text: '予約状態',
        value: 'resv_stat_desc',
        sortable: false,
        align: 'center',
        width: '10%',
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        align: 'right',
        width: '5%',
      },
    ],
  }),
  computed: {
    ...mapState('pelletStore', ['pelletIds']),
    ...mapState('customerStore', ['customerMaster']),
    ...mapState('reservedStore', ['reservedList', 'resvStatusMaster']),
    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
    formatPrice() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return parseFloat(data).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
  },
  watch: {
    selectedReserveds(val) {
      if (val.length) {
        if (val.resv_stat < 9) {
          this.openOperationDialog = false
        }
      }
    },
    pellet_id(val) {
      this.changeRouteQuery('pellet_id', val)
      this.loadData()
    },
    po_code(val) {
      this.changeRouteQuery('po_code', val)
      this.loadData()
    },

    cust_abbr(val) {
      this.changeRouteQuery('cust_id', val)
      this.loadData()
    },

    resv_stat(val) {
      this.changeRouteQuery('resv_stat', val)
      this.loadData()
    },

    startDate(val) {
      this.changeRouteQuery('startDate', val)
      this.loadData()
    },
    endDate(val) {
      this.changeRouteQuery('endDate', val)
      this.loadData()
    },
  },
  created() {
    this.loadQuery()
    this.loadData()
    console.log('created.')
  },
  destoryed() {
    this.clearCache()
  },

  methods: {
    ...mapActions('pelletStore', ['loadPelletIds']),
    ...mapActions('customerStore', ['loadCustomerIds']),
    ...mapActions('reservedStore', ['loadReserveds', 'loadReservationStatus']),
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapMutations('reservedStore', ['clearReservedList', 'clearReservedStatus']),
    ...mapMutations('customerStore', ['clearCustomerMaster']),

    pushProgress(item) {
      this.$router.push({
        name: 'reserved-progress',
        params: {
          id: item.resv_id,
          from: 1,
        },
      })
    },
    openReservedPreivew(item) {
      this.resvDialog = true
      this.selectedResvId = item.po_id
    },
    closeReservedPreivew() {
      this.resvDialog = false
    },

    openOperationDialog() {
      this.dialog = true
    },

    closeOperationDialog() {
      this.dialog = false
      this.loadData()
      this.selectedReserveds = []
    },
    editItem(item) {
      this.$router.push({
        name: 'reserved-edit',
        params: {
          id: item.resv_id,
        },
      })
    },
    clearCache() {
      this.clearReservedList()
      this.clearReservedStatus()
      this.clearCustomerMaster()
      this.clearPelletMaster()
    },
    loadQuery() {
      this.reserve_id = this.$route.query.reserve_id ?? ''
      this.cust_abbr = this.$route.query.cust_abbr ?? ''
      this.po_code = this.$route.query.po_code ?? ''
      this.resv_stat = this.$route.query.resv_stat ?? ''
      this.startDate = this.$route.query.startDate ?? ''
      this.endDate = this.$route.query.endDate ?? ''
    },
    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }
      this.$router.push({
        path: this.$route.path,
        query,
      })
    },
    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadCustomerIds(),
        this.loadPelletIds(),
        this.loadReservationStatus(),
        this.loadReserveds(this.$route.query),
      ]).then(() => {
        console.log('resved:', this.reservedList)
        this.preorderCodes = this.reservedList.map(rs => rs.po_code)
      }).catch(error => {
        console.log('loadData', error)
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },
    getColor(status) {
      if (status === 5) {
        return 'secondary'
      }

      if (status === 8) {
        return 'error'
      }

      if (status < 9) {
        return 'info'
      }

      if (status < 11) {
        return 'success'
      }

      return 'primary'
    },
  },
}
</script>
<style scoped>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
</style>
