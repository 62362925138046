var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"reserved-list"}},[_c('v-card',[_c('v-card-title',[_vm._v("予約実績(一覧)")]),_c('v-row',{staticClass:"px-2 ma-0"},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.pelletIds,"placeholder":"Pellet","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.pellet_id),callback:function ($$v) {_vm.pellet_id=$$v},expression:"pellet_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.preorderCodes,"placeholder":"予約商品","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.po_code),callback:function ($$v) {_vm.po_code=$$v},expression:"po_code"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.customerMaster,"placeholder":"取引先","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.cust_abbr),callback:function ($$v) {_vm.cust_abbr=$$v},expression:"cust_abbr"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.resvStatusMaster,"placeholder":"予約状態","outlined":"","clearable":"","dense":"","hide-details":""},model:{value:(_vm.resv_stat),callback:function ($$v) {_vm.resv_stat=$$v},expression:"resv_stat"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"placeholder":"予約日From","dense":"","hide-details":"","outlined":"","prepend-icon":_vm.icons.mdiCalendar,"clearable":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateStartMenu),callback:function ($$v) {_vm.dateStartMenu=$$v},expression:"dateStartMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateStartMenu = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"placeholder":"予約日To","dense":"","outlined":"","hide-details":"","prepend-icon":_vm.icons.mdiCalendar,"clearable":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateEndMenu),callback:function ($$v) {_vm.dateEndMenu=$$v},expression:"dateEndMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateEndMenu = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{staticClass:"mb-4",attrs:{"color":"secondary","outlined":""}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiExportVariant)+" ")]),_c('span',[_vm._v("Export")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.reservedList,"search":_vm.searchQuery,"item-key":"resv_id","show-select":""},scopedSlots:_vm._u([{key:"item.resv_id",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.pushProgress(item)}}},[_vm._v(_vm._s(item.resv_id))])]}},{key:"item.po_code",fn:function(ref){
var item = ref.item;
return [_c('a',{style:('text-decoration: underline;'),attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.openReservedPreivew(item)}}},[_vm._v(_vm._s(item.po_code))])]}},{key:"item.cust_abbr",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({style:('text-decoration: underline; color:#666666')},'a',attrs,false),on),[_vm._v(_vm._s(item.cust_abbr))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.cust_id))]),_c('br'),_c('span',[_vm._v(_vm._s(item.cust_name))]),_c('br'),_c('span',[_vm._v(_vm._s(item.cust_name_eng))]),_c('br')])]}},{key:"item.resv_count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatData(item.resv_count))+" ")]}},{key:"item.resv_weight",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatData(item.resv_weight))+" ")]}},{key:"item.resv_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ccy_mark)+_vm._s(_vm.formatPrice(item.resv_amount))+" ")]}},{key:"item.resv_stat_desc",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-chip',{staticClass:"d-flex justify-center",staticStyle:{"width":"100px"},attrs:{"color":_vm.getColor(item.resv_stat)}},[_vm._v(" "+_vm._s(item.resv_stat_desc)+" ")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","disabled":item.resv_stat !== 1},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(item.resv_stat !== 1 ? _vm.icons.mdiPencilOff : _vm.icons.mdiPencil)+" ")])]}}],null,true),model:{value:(_vm.selectedReserveds),callback:function ($$v) {_vm.selectedReserveds=$$v},expression:"selectedReserveds"}})],1),_c('reserved-operation',{attrs:{"dialog":_vm.dialog,"selected-reserveds":_vm.selectedReserveds},on:{"closeForm":_vm.closeOperationDialog}}),_c('ReservedPreview',{attrs:{"id":_vm.selectedResvId,"resv-dialog":_vm.resvDialog},on:{"closeForm":_vm.closeReservedPreivew}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }